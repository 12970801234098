<template>
    <div class="all">

        <div class="tour-menu bike">
            <h2>Список Гидов</h2>
            <ul>
                                <!-- Рендеринг списка гидов -->
                <li v-for="(guide, index) in guides" :key="index" @click="showTourDates(index)">
                    <div v-if="!guide.isEditing">
                        {{ guide.name }} - {{ guide.phone }}
                                                <!-- Кнопка для редактирования гида -->
                        <button @click="editTour(index)">
                            <i class="fas fa-edit"></i>
                        </button>
                        <button @click="deleteGuide(index)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                        <!-- Форма для редактирования гида -->
                    <div v-else>
                        <input type="text" placeholder="введите название" v-model="guides[index].name">
                        <input type="text" placeholder="введите телефон или заметку" v-model="guides[index].phone">
                        <!-- Кнопки для сохранения и удаления гида -->
                        <button @click="saveGuide(index)">Сохранить</button>
                        <button @click="deleteGuide(index)">Удалить</button>
                    </div>
                </li>
            </ul>
            <button @click="addNewTour">Добавить гида</button>

        </div>

    </div>
</template>
  
<script>
import axios from 'axios';
import { BASE_URL } from '../config'; // Импортируем базовый URL

import '@fortawesome/fontawesome-free/css/all.css';
export default {

    data() {
        return {
          
            selectedTour: null,
            editIndex: null,
            motos: [],
            guides: [],

        };
    },
    created() {
        this.fetchTours();
        // Получение токена из локального хранилища
        const storedToken = localStorage.getItem('setUserRole');

        // Проверка наличия токена в локальном хранилище
        if (storedToken) {
            try {
                // Парсинг JSON-строки в объект
                const tokenData = JSON.parse(storedToken);
                // Получение токена из объекта (если он хранится прямо в объекте)
                const token = tokenData.token;
                // Используйте токен по вашему усмотрению
                console.log('Stored token:', token);
            } catch (error) {
                console.error('Error parsing stored token:', error);
            }
        }
    },
    methods: {
                // Функция для отображения списка гидов
        showTourDates(index) {
            if (this.selectedTour === index) {
                this.selectedTour = null;
            } else {
                this.selectedTour = index;
                this.editIndex = null; // Сбрасываем индекс редактирования
            }
        },

             // Функция для получения списка гидов   async fetchTours()
        async fetchTours() {
            try {
                const response = await axios.get(`${BASE_URL}/guides`);
                console.log('API Response:', response);
                console.log('API Data:', response.data);
                // Преобразование данных гидов и сохранение их в состоянии компонента
                this.guides = response.data.map(guide => {
                    return {
                        id: guide.guide_id,
                        name: guide.guide_name ? guide.guide_name.trim() : '',
                        phone: guide.guide_phone ? guide.guide_phone.trim() : '',
                        // Add other properties based on your data structure
                        isEditing: false, // You may want to add this property
                    };
                });

                // Log names of all guides
                this.guides.forEach(guide => {
                    console.log(guide.id);
                    console.log(guide.name)
                });
            } catch (error) {
                console.error('Error fetching guides:', error);
            }
        },

// удаление гида
        async deleteGuide(index) {
            try {
                const confirmation = confirm('Вы уверены, что хотите удалить этот мотоцикл');
                if (confirmation) {
                    const guidesId = this.guides[index].id;

                    try {
                        await axios.delete(`${BASE_URL}/guides/${guidesId}`);
                        console.log(`${BASE_URL}/moto/${guidesId}`);
                        this.guides.splice(index, 1); // Remove the deleted moto directly
                        this.selectedTour = null;
                    } catch (error) {
                        console.error('Error deleting moto:', error);
                    }
                }
            } catch (error) {
                console.error('Error handling delete confirmation:', error);
            }
        },
//  // Функция для сохранения изменений гида saveGuide(index)
        saveGuide(index) {
            this.guides[index].isEditing = false;

            const guidesId = this.guides[index].id;
            // Добавляем console.log для вывода в консоль
            console.log('Data before saving:', this.guides[index].name);
            // console.log('id', motoId)

            const requestData = new URLSearchParams();
            requestData.append('guide_name', this.guides[index].name);
            requestData.append('guide_phone', this.guides[index].phone);
            axios.put(`${BASE_URL}/guides/${guidesId}`, requestData.toString(), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
                .then(response => {
                    console.log('Tour updated successfully:', response.data);
                    this.guides[index].name = response.data.guide_name;
                    this.guides[index].phone = response.data.guide_phone;

                })
                .catch(error => {
                    console.error('Error updating tour:', error);
                    this.fetchTours(); // Вернем данные к исходным
                });
        },

        dateToString(date) {
            return date.toISOString().substr(0, 10);
        },
        stringToDate(string) {
            const [day, month, year] = string.split('.');
            return new Date(`${year}-${month}-${day}`);
        },
        editTourDate(dateIndex) {
            this.startEditing(dateIndex);
        },
        editTour(index) {
            this.editedTourName = this.guides[index].name;
            this.editedTourName = this.guides[index].phone;
            this.guides[index].isEditing = true;
        },
        // addNewTour() {

        // },

        async addNewTour() {
            try {
                // Отправка POST-запроса для добавления нового тура
                const response = await axios.post(`${BASE_URL}/guides`, {
                    guide_name: 'Новый гид',  // Убедитесь, что здесь указано правильное поле
                    guide_phone: 'Введите телефон или заметку'
                    // Добавьте другие поля тура, если необходимо
                });
                this.guides.push({ guide_name: 'Новый гид', guide_phone: 'Введите телефон или заметку' });

                // Обработка успешного добавления тура
                console.log('Гид добавлен:', response.data);

                // Опционально: обновление списка туров после добавления нового тура
                this.fetchTours();
            } catch (error) {
                console.error('Error adding tour:', error);
            }
        },


    },
};
</script>
  
<style>
div.tour-menu.bike{
    padding-left: 5%;
}
/* кнопки */
.edit-button,
.delete-button {
    padding: 10px;
    margin: 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-button:hover,
.delete-button:hover {
    background-color: #2980b9;
}

.edit-button i,
.delete-button i {
    margin-right: 5px;
}

/* конец кнопок */
.editData {
    display: flex;
    justify-content: center;
}

.all {
    display: flex;
    /* margin: 0 auto; */
    max-width: 1200px;
    justify-content: space-between;
}


.padding_palace {
    padding-right: 20px;
    /* Добавление стилей, чтобы текст выглядел как ссылка при наведении курсора */
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.padding_palace:hover {
    /* Изменение цвета текста при наведении курсора */
    color: red;
}

.padding_palace:active {
    /* Изменение цвета текста при клике */
    color: green;
}

/* Стилизация для внешнего вида списка туров и дат */
.tour-menu .bike {
    float: left;
    width: 400px;
    margin-right: 20px;
}

.tour-menu ul {
    list-style: none;
    padding: 0;
    cursor: pointer;
}

.tour-menu ul li {
    margin: 5px 0;
    color: blue;
}

.tour-menu ul li:hover {
    text-decoration: underline;
}

.tour-dates {
    clear: both;
    /* display: flex;
    flex-wrap: wrap; */
}

.tour-dates ul {
    list-style: none;
    padding: 0;
}

.tour-dates ul li {
    margin: 5px 0;
    color: green;
}
</style>
  
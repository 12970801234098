<template>
  <div>
    <h2 style="color: #333;">Изменить данные</h2>
    <form @submit.prevent="submitForm" style="max-width: 400px; margin: 0 auto;">
      <div class="form-group">
        <label for="login" style="color: #666;">Введите Логин для которого хотите поменять данные:</label>
        <input v-model="login" type="text" id="login" required>
      </div>

      <div class="form-group">
        <label for="password" style="color: #666;">Пароль:</label>
        <input v-model="password" type="password" id="password" required>
      </div>
      <!-- Поле для повторного ввода пароля -->
      <div class="form-group">
        <label for="confirmPassword" style="color: #666;">Повторите пароль:</label>
        <input v-model="confirmPassword" type="password" id="confirmPassword" required>
      </div>

      <div class="form-group">
        <label for="email" style="color: #666;">Email:</label>
        <input v-model="email" type="email" id="email" required>
      </div>

      <button type="submit"
        style="background-color: #3498db; color: #fff; padding: 10px 15px; border: none; cursor: pointer;">Обновить
        профиль</button>
    </form>
  </div>
</template>
  
<script>
import axios from 'axios';
import { BASE_URL } from '../config'; // Импортируем базовый URL
export default {
  data() {
    return {
      login: '',
      password: '',
      confirmPassword: '',//повторный пароль
      email: '',
    };
  },
  methods: {
    // отправка формы
    async submitForm() {
      // Проверка наличия значений в обязательных полях
      if (!this.login || !this.password || !this.confirmPassword || !this.email) {
        alert('Пожалуйста, заполните все обязательные поля.');
        return;
      }

      // Проверка совпадения паролей
      if (this.password !== this.confirmPassword) {
        alert('Пароли не совпадают. Пожалуйста, проверьте введенные данные.');
        return;
      }

      try {
        // Логика отправки формы (например, запрос на сервер)
        const response = await axios.put(`${BASE_URL}/admin/update-admin-profile-no-auth`, {
          login: this.login,
          profileData: {
            password: this.password,
            email: this.email,
          }
        });

        console.log('Ответ от сервера:', response.data);

        // Очистка полей после успешной отправки
        this.login = '';
        this.password = '';
        this.confirmPassword = '';
        this.email = '';

        // Вывод сообщения об успешном обновлении профиля
        alert('Спасибо! Ваши данные успешно  изменены.');
      } catch (error) {
        console.error('Ошибка при отправке запроса:', error);

        // Обработка ошибок (например, вывод ошибки на экран)
        alert('Произошла ошибка при обновлении профиля. Пожалуйста, попробуйте еще раз.');
      }
    },
  },
};
</script>
  
<style scoped>
.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}
</style>
  
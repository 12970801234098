<template>
  <div id="app">
    <!-- Форма для поиска или добавления пользователя -->
    <form @submit.prevent="searchOrAdd">

      <label for="search">Поиск по ФИО:</label>
      <input type="text" v-model="searchText" id="search" @input="searchOrAdd">
            <!-- Поле ввода для поиска -->
      <select v-if="matchingPeople.length > 0" v-model="selectedPerson"> 
        <!-- Выпадающий список с найденными пользователями -->
        <option v-for="person in matchingPeople" :key="person.fio" :value="person">
         
          {{ person.fio }}
        </option>
      </select>
    </form>

    <div>
            <!-- Результаты поиска -->
      <h2>Результаты поиска:</h2>
      <div>
        <template v-if="searchResults.length > 0">
                <!-- Форма для добавления нового пользователя -->
          <form @submit.prevent="addPerson(selectedPerson.id)" v-if="searchResults.length > 0 && selectedPerson">
            <!-- Поля для заполнения данными о пользователе -->
            <div class="my_div">
              <label for="newFio">ФИО:</label>
              <input type="text" v-model="selectedPerson.fio" id="newFio" readonly>

              <label for="newbirthDate">Дата рождения:</label>
              <input v-model="selectedPerson.DateBirth"  id="newbirthDate" readonly />

              <label for="newPhone">Телефон:</label>
              <input v-model="selectedPerson.phone"  id="newPhone" readonly />
            </div>
            <h2>Паспорт</h2>
            <div class="my_div">
              <label for="newSeriaPassport">Cерия:</label>
              <input v-model="selectedPerson.passportSeries" id="newSeriaPassport" readonly />

              <label for="newNumberPassport">Номер:</label>
              <input v-model="selectedPerson.passportNumber" id="newNumberPassport" readonly />


              <label for="newDataPassport">Дата выдачи:</label>
              <input v-model="selectedPerson.passportVydan" id="newDataPassport"
                readonly />

              <label for="newKodPassport">Код подразделения:</label>
              <input v-model="selectedPerson.passportKodP" id="newKodPassport" readonly />

              <label for="newBirthdate">Дата регистрации:</label>
              <input v-model="selectedPerson.birthdate"  id="newBirthdate" readonly />



              <label for="newBirthplace">Место рождения:</label>
              <input v-model="selectedPerson.birthplace" id="newBirthplace" readonly />


            </div>

            <div class="my_div">
              <label for="newKemVydanPassport">Кем выдан:</label>
              <textarea v-model="selectedPerson.passportKemVydan" required id="newKemVydanPassport" cols="50" rows="5" />

              <label for="newregistrationAddress">Место регистрации:</label>
              <textarea v-model="selectedPerson.registrationAddress" required v-maska data-maska="##.##.####"
                id="newRegistrationAddress" cols="50" rows="5" readonly />
            </div>

            <!-- <label for="newRegistrationAddress">Место регистрации:</label>
            <textarea v-model="selectedPerson.registrationAddress" v-maska data-maska="##.##.####"
              id="newRegistrationAddress" readonly /> -->


            <h2>Водительское удостоверение</h2>
            <div class="my_div">
              <label for="newNumberPrava">Номер:</label>
              <input v-model="selectedPerson.driverLicense" id="newNumberPrava" readonly />

              <label for="newDataPrava">Дата выдачи:</label>
              <input v-model="selectedPerson.driverLicenseDate" type="date" id="newDataPrava" readonly />

            </div>
            <h2>Тур </h2>

            <label for="tourSelect">Выберите тур:</label>
            <select id="tourSelect" @change="fetchTours">
              <option value="" disabled>Выберите тур:</option>
              <option v-for="(tour, index) in tours" :key="index" :value="tour.id">{{ tour.tour_name }}</option>
            </select>

            <select v-model="selectedTourDate" id="dataSelect" v-if="tourDates.length > 0" @change="handleTourDateChange">
  <option value="" disabled>Выберите дату тура:</option>
  <option v-for="(tour_date, index) in formattedTourDates" :key="index" :value="tour_date.tour_date">
    {{
      tour_date.formattedStartDate }} - {{
      tour_date.formattedEndDate }}
  </option>
</select>

            <!-- Используйте v-model для отображения идентификатора первой даты в инпуте -->
            <input v-model="firstDateId" type="hidden" id="firstDateIdInput">

           
            <!-- Поля для ввода информации о цене тура -->
            <label for="newPrice">Цена:</label>
            <input v-model="newPrice" type="number" id="newPrice" />
            <label for="newDoplata">Доплата:</label>
            <input v-model="newDoplata" type="number" id="newDoplata" />
            <label for="newAllPrice">Общая сумма:</label>
            <input v-model="newAllPrice" type="number" id="newAllSumma" />

            <label for="newAvance">Аванс:</label>
            <input v-model="newAvance" type="number" id="newAvance" />

            <label for="newdecimal">Скидка % на тур:</label>
            <input v-model="newdecimal" type="number" id="newdecimal" />

            <!-- <label for="discountedPrice">Цена тура со скидкой:</label>
            <input v-model="discountedPrice" type="number" id="discountedPrice" /> -->



            <h2>Арендная техника</h2>
            <!-- Выбор арендной техники -->
            <select v-model="selectedOption" @change="loadData">
              <option value="" disabled>Выберите арендную технику</option>

              <!-- Populate options dynamically based on your data -->
              <option v-for="moto in motoList" :value="moto.id" :key="moto.id">{{ moto.equipment_name }}</option>
            </select>
            <!-- <select v-model="selectedOption" @change="loadData">
  <option value="">Выберите арендную технику</option>
  <option v-for="moto in motoList" :value="moto.id" :key="moto.id">{{ moto.equipment_name }}</option>
</select> -->

            <label v-if="selectedMoto">
              <input type="text" v-model="selectedMotoPrice" id="input1" />

            </label>
            <label for="numberOfDays" v-if="selectedOption">Количество дней:</label>
            <input id="input2" @input="multiplyValues" v-model="newDay" type="number" min="1"
              :disabled="!selectedOption" />


            <!-- <label for="newDay">Количество дней:</label>
<input type="number" @input="multiplyValues" v-model="newDay" id="input2" /> -->

            <label for="newRental_cost">К оплате:</label>
            <input type="number" @input="multiplyValues" v-model="newRental_cost" id="result" />
            <!-- <label for="newTotal_rental_cost">Общая сумма:</label>
            <input type="number" v-model="newTotal_rental_cost" id="newTotal_rental_cost" /> -->


            <h2>коментариии</h2>
            <textarea name="" v-model="newDescription" id="" cols="100" rows="10"></textarea>
            <br>
            <button type="submit">Добавить</button>
          </form>
        </template>

          <!-- Если ничего не найдено  Добавление нового пользователя-->
        <template v-else>
          <p>Ничего не найдено</p>
                    <!-- Форма для добавления нового пользователя -->
          <form @submit.prevent="newaddPerson">

            <h2>Добавить нового клиента</h2>
            <div class="my_div">
              <label for="newFio">ФИО:</label>
              <input type="text" v-model="newFio" required id="newFio">

              <label for="newbirthDate">Дата рождения:</label>
              <input v-model="newDateBirth" type="date" id="newbirthDate" required  />

              <label for="newPhone">Телефон:</label>
              <input v-model="newPhone" required  id="newPhone" />
            </div>

            <h2>Паспорт</h2>
            <div class="my_div">
              <label for="newSeriaPassport">Cерия:</label>
              <input v-model="newSeriaPassport" required id="newSeriaPassport" />

              <label for="newNumberPassport">Номер:</label>
              <input v-model="newNumberPassport" required id="newNumberPassport" />

              <!-- <label for="newKemVydanPassport">Кем выдан:</label>
              <textarea v-model="newKemVydanPassport" required id="newKemVydanPassport" /> -->




              <label for="newDataPassport">Дата выдачи:</label>
              <input v-model="newDataPassport" type="date" required id="newDataPassport"  />

              <label for="newKodPassport">Код подразделения:</label>
              <input v-model="newKodPassport" required id="newKodPassport" />

              <label for="newbirthdate">Когда зарегестрирован:</label>
              <input v-model="newBirthdate" type="date" required id="newBirthdate"  />

              <label for="newBirthplace">Место рождения:</label>
              <input v-model="newBirthplace" required id="newBirthplace"  />

            </div>

            <div class="my_div">
              <label for="newKemVydanPassport">Кем выдан:</label>
              <textarea v-model="newKemVydanPassport" required id="newKemVydanPassport" cols="50" rows="5" />

              <label for="newregistrationAddress">Место регистрации:</label>
              <textarea v-model="newRegistrationAddress"  required 
                id="newRegistrationAddress" cols="50" rows="5" />
            </div>


            <h2>Водительское удостоверение</h2>
            <div class="my_div">
              <label for="newNumberPrava">Номер:</label>
              <input v-model="newNumberPrava" required id="newNumberPrava" />

              <label for="newDataPrava">Дата выдачи:</label>
              <input v-model="newDataPrava"  type="date" required id="newDataPrava"  />
            </div>
            <h2>Тур </h2>

            <label for="tourSelect">Выберите тур:</label>
            <select id="tourSelect" @change="fetchTours">
              <option value="" disabled>Выберите тур:</option>
              <option v-for="(tour, index) in tours" :key="index" :value="tour.id">{{ tour.tour_name }}</option>
            </select>

            <select v-model="selectedTourDate" id="dataSelect" v-if="tourDates.length > 0" @change="handleTourDateChange">
  <option value="" disabled>Выберите дату тура:</option>
  <option v-for="(tour_date, index) in formattedTourDates" :key="index" :value="tour_date.tour_date">
    {{
      tour_date.formattedStartDate }} - {{
      tour_date.formattedEndDate }}
  </option>
</select>

            <!-- Используйте v-model для отображения идентификатора первой даты в инпуте -->
            <input v-model="firstDateId" type="hidden" id="firstDateIdInput">

            <label for="newPrice">Цена:</label>
            <input v-model="newPrice" type="number" id="newPrice" />
            <label for="newDoplata">Доплата:</label>
            <input v-model="newDoplata" type="number" id="newDoplata" />
            <label for="newAllPrice">Общая сумма:</label>
            <input v-model="newAllPrice" type="number" id="newAllSumma" />

            <label for="newAvance">Аванс:</label>
            <input v-model="newAvance" type="number" id="newAvance" />




            <label for="newdecimal">Скидка % на тур:</label>
            <input v-model="newdecimal" type="number" id="newdecimal" />
            <!-- You might want to display an error message if needed -->
            <div v-if="newdecimal < 0">Пожалуйста, введите положительное число от 0 </div>
            <!-- <label for="discountedPrice">Цена тура со скидкой:</label>
            <input v-model="discountedPrice" type="number" id="discountedPrice" /> -->

            <h2>Арендная техника</h2>

            <select v-model="selectedOption" @change="loadData">
              <option value="" disabled>Выберите арендную технику</option>

              <!-- Populate options dynamically based on your data -->
              <option v-for="moto in motoList" :value="moto.id" :key="moto.id">{{ moto.equipment_name }}</option>
            </select>
            <!-- <select v-model="selectedOption" @change="loadData">
              <option value="">Выберите арендную технику</option>
              <option v-for="moto in motoList" :value="moto.id" :key="moto.id">{{ moto.equipment_name }}</option>
            </select> -->

            <label v-if="selectedMoto">
              <input type="text" v-model="selectedMotoPrice" id="input1" />

            </label>
            <label for="numberOfDays" v-if="selectedOption">Количество дней:</label>
            <input id="input2" @input="multiplyValues" v-model="newDay" type="number" min="1"
              :disabled="!selectedOption" />


            <!-- <label for="newDay">Количество дней:</label>
            <input type="number" @input="multiplyValues" v-model="newDay" id="input2" /> -->

            <label for="newRental_cost">К оплате:</label>
            <input type="number" @input="multiplyValues" v-model="newRental_cost" id="result" />

            <!-- <label for="newTotal_rental_cost">Общая сумма:</label>
            <input type="number" v-model="newTotal_rental_cost" id="newTotal_rental_cost" /> -->

            <h2>коментариии</h2>
            <textarea name="" v-model="newDescription" id="" cols="100" rows="10"></textarea>
            <br>
            <button type="submit">Добавить в базу пользователя </button>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { vMaska } from "maska"
import { format } from 'date-fns';
import { ru } from 'date-fns/locale'; // Для русского языка
import { BASE_URL } from '../config'; //глобавльный url
export default {
// Регистрация директивы maska для использования в шаблоне
  directives: { maska: vMaska },
  
  data() {

    return {
      
      people: [],
      maskedValue: "",
      bindedObject: {
        masked: "",
        unmasked: "",
        completed: false
      },
      result :0,
      searchText: '',
      matchingPeople: [],
      searchResults: [],
      newFio: '',
      newPhone: '',
      newDescription: '',
      // newbirthDate: '',
      // birthdate:null,

      newBirthdate: '',
      newBirthplace: '',
      newRegistrationAddress: '',
      selected: '',
      selectedPerson: null,
      tours: [],
      NewDateId: null,
      tours1: '',
      firstDateId: null,
      selectedTourDate: null,
      selectedTourDateId: null,
      tourDates: [],
      tourSelect: '',
      dataSelect: '',
      selectedTour: null,
      tourId: null,
      selectedOption: null,
      selectedValue2: null,
      newdecimal: 0,
      discountedPrice: 0,

      options1: [

      ],
      selectedOptionMoto: null,
      newPrice: 0,
      newDoplata: 0,
      newAllPrice: 0,
      newAvance: 0,
      motos: [],
      selectedMotoId: [],
      // все данные которые используем 

      motoList: [],
      selectedMoto: null,
      selectedMotoPrice: 0,//счетчик выбранных мотоциклов
      newDay: 0,
      newRental_cost: 0,
      newTotal_rental_cost: 0

    };
  },
  async mounted() {
    await this.fetchTours();

    this.loadMotoList();
  },

  computed: {
    // Этот вычисляемый метод используется для вычисления общей суммы аренды с учетом скидки. Он учитывает базовую стоимость, доплату и скидку, и возвращает итоговую сумму.
    totalSum: function () {
      let baseSum = parseFloat(this.newPrice);
      let doplata = parseFloat(this.newDoplata);
      let discount = parseFloat(this.newdecimal);

      if (isNaN(baseSum)) {
        baseSum = 0;
      }

      if (isNaN(doplata)) {
        doplata = 0;
      }

      if (isNaN(discount)) {
        discount = 0;
      }

      // Calculate the total sum without discounting the additional payment
      let totalSum = baseSum + doplata;

      // Apply discount only to the base sum
      let discountedAmount = (baseSum * discount) / 100;
      totalSum -= discountedAmount;

      return totalSum;
    },
   
    // Вычисляемый аванс (30% от суммы)
    advance: function () {
      return this.newPrice * 0.3;
    },
  // formattedTourDates: Этот вычисляемый метод форматирует даты туров на русском языке и возвращает массив объектов с отформатированными датами.
    formattedTourDates() {
    return this.tourDates.map((tour_date) => {
      try {
        const formattedStartDate = format(new Date(tour_date.tour_date), 'd MMMM yyyy', { locale: ru });
        const formattedEndDate = format(new Date(tour_date.tour_date_end), 'd MMMM yyyy', { locale: ru });

        return {
          ...tour_date,
          formattedStartDate,
          formattedEndDate,
        };
      } catch (error) {
        console.error('Error formatting date:', error);
        console.log('Problematic tour_date:', tour_date);
        return null; // or handle the error in a way that fits your use case
      }
    }).filter(Boolean); // Remove null values from the array
  },

  },

  
  watch: {
    // totalSum: Этот наблюдатель следит за изменениями в totalSum и обновляет поле newAllPrice в случае изменения.
    totalSum: function (newValue) {
      this.newAllPrice = newValue;
    },
    // advance: Этот наблюдатель следит за изменениями в advance и обновляет поле newAvance в случае изменения.
    advance: function (newValue) {
      this.newAvance = newValue;
    },

    tourDatesId: [],
    selectedOption: {
      handler: 'loadData',
      immediate: true,
    },

    // Watcher for other properties
    'selectedMotoPrice': {
      handler: 'calculateTotalCost',//// Вызывает метод calculateTotalCost при изменении selectedMotoPrice.
      deep: true // Глубокое наблюдение за изменениями.
    },
    'newDay': {
      handler: 'calculateTotalCost', //Вызывает метод calculateTotalCost при изменении newDay.
      deep: true // Глубокое наблюдение за изменениями.
    },
  },
  methods: {
    // Проверка, чтобы значение newdecimal было неотрицательным
    validateNewDecimal() {
      if (this.newdecimal < 0) {
        // You can set it to zero or display an error message.
        this.newdecimal = 0;
        // Alternatively, show an error message
        // this.errorMessage = "Please enter a non-negative number.";
      }
    },
        // Обработчик изменения выбранной даты тура
    handleTourDateChange() {
      // Поиск выбранной даты в массиве tourDates
      const selectedDate = this.tourDates.find(date => date.tour_date === this.selectedTourDate);

      // Установка значения firstDateId в id выбранной даты
      this.firstDateId = selectedDate ? selectedDate.date_id : null;
    },
      // Умножение значений из двух input'ов и отображение результата
    multiplyValues() {
      // Получаем значения из инпутов по их id
      const value1 = parseFloat(document.getElementById('input1').value) || 0;
      const value2 = parseFloat(document.getElementById('input2').value) || 0;

      // Производим вычисление
      this.result = value1 * value2;

      document.getElementById('result').value = isNaN(this.result) ? '' : this.result;
    },

    // отправка данных в первое значение когда пользователь найден в базе данных

    async addPerson(personId) {
      try {

        console.log(personId)
        // Получаем выбранный элемент напрямую из модели данных Vue.js
        const selectedOption = this.selectedTourDate;


        // Выводим выбранный элемент в консоль
        console.log('Selected Option:', selectedOption);
        // const response4 = await axios.get(`${this.apiUrl}/tour-dates/${this.selectedOption}`);

        // const selectResponseData = response4.data
        console.log('вывод цифры', this.firstDateId)
        // Переводим значение в число (используйте parseFloat или parseInt в зависимости от ваших потребностей)
console.log('цена аренды', this.result)
        const selectedMotoId = this.selectedOption;
        console.log('Selected Moto ID:', selectedMotoId);
        console.log('скидка', this.newdecimal)
        if (selectedMotoId == null) {   // Переводим значение в число (используйте parseFloat или parseInt в зависимости от ваших потребностей)
          // const selectedOptionMoto = parseFloat(inputValue);
          const dataToSendBikeArenda = {
            user: parseInt(personId),
            rental_days: parseInt(this.newDay),
            description: this.newDescription,
            rental_cost: parseInt(this.newRental_cost),

            total_rental_cost: this.result,

            // moto: selectedMotoId,
            rental_tour: parseInt(this.newPrice),
            total_tour: parseInt(this.newAllPrice),
            doplata_tour: parseInt(this.newDoplata),
            avance_tour: parseInt(this.newAvance),
            tourDate: parseInt(this.firstDateId),
            percent: parseInt(this.newdecimal),
        
            // total_rental_cost: newRental_cost,

            // "status_id": 2

          };
          console.log('вывод user', dataToSendBikeArenda)

          const response2 = await axios.post(`${BASE_URL}/arenda-status`, dataToSendBikeArenda)

          console.log(response2)


          alert('Данные успешно отправлены на сервер!');


        } else {


          // Переводим значение в число (используйте parseFloat или parseInt в зависимости от ваших потребностей)
          // const selectedOptionMoto = parseFloat(inputValue);
          const dataToSendBikeArenda = {
            user: parseInt(personId),
            rental_days: parseInt(this.newDay),
            description: this.newDescription,
            rental_cost: parseInt(this.newRental_cost),

            total_rental_cost: this.result,

            moto: selectedMotoId,
            birthdate: this.newbirthdate,
            birthplace: this.newbirthplace,
            registrationAddress: this.registrationAddress,
            rental_tour: parseInt(this.newPrice),
            total_tour: parseInt(this.newAllPrice),
            doplata_tour: parseInt(this.newDoplata),
            avance_tour: parseInt(this.newAvance),
            tourDate: parseInt(this.firstDateId),
            percent: parseInt(this.newdecimal),
            // total_rental_cost: newRental_cost,

            // "status_id": 2

          };
          console.log('вывод user', dataToSendBikeArenda)

          const response2 = await axios.post(`${BASE_URL}/arenda-status`, dataToSendBikeArenda)

          console.log(response2)


          alert('Данные успешно отправлены на сервер!');
        }
      } catch (error) {
        console.error('Ошибка отправки данных:', error);
        alert('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова.');
      }
    },

    // Обработчик изменения значения selectedTourDateId
    handleSelectedTourDateIdChange() {
      console.log('Handling Selected Tour Date ID Change');
      console.log('Selected Tour Date ID:', this.selectedTourDateId);
    },
    
    // отправка ко второе значение, когда пользователь не найден, добавляем пользователя и все данные по нему
    async newaddPerson() {
      try {
        // console.log(response4)
        console.log('вывод цифры', this.firstDateId)
        // Переводим значение в число (используйте parseFloat или parseInt в зависимости от ваших потребностей)
        const selectedMotoId = this.selectedOption ? parseInt(this.selectedOption) : null;

        if (selectedMotoId !== null) {
          console.log('Selected Moto ID:', selectedMotoId);

        } else {
          console.log('Moto ID is not selected or invalid.');
        }
        if (selectedMotoId == null) {
          const dataToSend = {
            user: {
              fio: this.newFio,
              DateBirth: this.newDateBirth,
              phone: this.newPhone,
              passportSeries: this.newSeriaPassport,
              passportNumber: this.newNumberPassport,
              passportKemVydan: this.newKemVydanPassport,
              passportVydan: this.newDataPassport,
              passportKodP: this.newKodPassport,
              driverLicense: this.newNumberPrava,
              driverLicenseDate: this.newDataPrava,
           
              birthdate: this.newBirthdate,
              birthplace: this.newBirthplace,
              registrationAddress: this.newRegistrationAddress,
            },
            arenda: {
              rental_days: parseInt(this.newDay),
              description: this.newDescription,
              rental_cost: parseInt(this.newRental_cost),
              total_rental_cost: this.result,
              percent: parseInt(this.newdecimal),
              rental_tour: parseInt(this.newPrice),
              total_tour: parseInt(this.newAllPrice),
              doplata_tour: parseInt(this.newDoplata),
              avance_tour: parseInt(this.newAvance),
              tourDate: parseInt(this.firstDateId),

            },

          };
          console.log('dataToSend', dataToSend)
          const response1 = await axios.post(`${BASE_URL}/arenda-status/users-and-arenda`, dataToSend);
          console.log(response1)
          alert('Данные успешно отправлены на сервер!');

        } else {
          const dataToSend = {
            user: {
              fio: this.newFio,
              DateBirth: this.newDateBirth,
              phone: this.newPhone,
              passportSeries: this.newSeriaPassport,
              passportNumber: this.newNumberPassport,
              passportKemVydan: this.newKemVydanPassport,
              passportVydan: this.newDataPassport,
              passportKodP: this.newKodPassport,
              driverLicense: this.newNumberPrava,
              driverLicenseDate: this.newDataPrava,
              // 
              birthdate: this.newBirthdate,
              birthplace: this.newBirthplace,
              registrationAddress: this.newRegistrationAddress,
            },
            arenda: {
              rental_days: parseInt(this.newDay),
              description: this.newDescription,
              rental_cost: parseInt(this.newRental_cost),
              total_rental_cost: this.result,
              moto: selectedMotoId,
              rental_tour: parseInt(this.newPrice),
              total_tour: parseInt(this.newAllPrice),
              doplata_tour: parseInt(this.newDoplata),
              avance_tour: parseInt(this.newAvance),
              tourDate: parseInt(this.firstDateId),
              percent: parseInt(this.newdecimal),

            },
          };
console.log('dataToSend', dataToSend )
          const response1 = await axios.post(`${BASE_URL}/arenda-status/users-and-arenda`, dataToSend);
          console.log(response1)
          alert('Данные успешно отправлены на сервер!');
        }
        // Формируем объект с данными для отправки на сервер


      } catch (error) {
        console.error('Ошибка отправки данных:', error);
        alert('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова.');
      }
    },

// Поиск или добавление человека
    async searchOrAdd() {
      try {
        if (!this.searchText.trim()) {
          // Если строка поиска пуста или состоит только из пробелов, выполнить сброс
          this.selectedPerson = null;
          this.searchResults = [];
          return;
        }

        const response = await axios.get(`${BASE_URL}/users/fio/${encodeURIComponent(this.searchText)}`);

        this.matchingPeople = response.data;

        if (this.matchingPeople.length !== 0) {
          // Если найдены совпадения, выбрать первого в списке
          this.selectedPerson = this.matchingPeople[0];
          console.log('ID выбранного человека:', this.selectedPerson.id);
          this.searchResults = this.matchingPeople;

          console.log();
        } else {
          console.log('сброс');
          // Если совпадений не найдено, очистить результаты
          this.selectedPerson = null;
          this.searchResults = [];
        }
      } catch (error) {
        console.error('Error fetching data from the API:', error);
      }
    },

    // Получение списка туров  
    async fetchTours() {
      try {

        const response = await axios.get(`${BASE_URL}/tours?limit=100&skip=0`);
        this.tours = response.data;
        console.log('Тур апи:', this.tours);
        // loadMotoList(tours)
        // Вызываем fetchDataTours сразу после получения списка туров



        var selectElement = document.getElementById("tourSelect");
        var isHandlingEvent = false;

        selectElement.addEventListener('change', async () => {
          if (isHandlingEvent) {
            return;

          }
          this.tourId = null;
          isHandlingEvent = true;
          var selectedValue = selectElement.value;

          // Проверка, что selectedValue не равен null, undefined и не пустая строка
          if (selectedValue !== null && selectedValue !== undefined && selectedValue.trim() !== "") {
            const response1 = await axios.get(`${BASE_URL}/tours/name-tour/${selectedValue}`);
            this.tourId = response1.data.tour_id;

            console.log('Выбранное значение:', selectedValue);
            console.log('Ответ от сервера:', this.tourId);

            this.loadMotoList(this.tourId)
            this.fetchDataTours(this.tourId)
          }
          // this.fetchDataToursId(this.tourId)
          // console.log('значение даты id', this.tourId)
          // this.fetchDataTours(tourId);

          isHandlingEvent = false;
        });
      } catch (error) {
        console.error('Error fetching tours:', error);
      }
    },

    // Получение данных о турах 
    async fetchDataTours(tourId) {
      try {

        if (!this.tourId) {
          // Handle the case where this.tourId is not defined
          return;
        }
        const response3 = await axios.get(`${BASE_URL}/tour-dates/${tourId}/tour-date`);

        this.tourDates = response3.data.map(item => item.tour_date);
        console.log('tour_dates:', this.tourDates);
        this.fetchDataToursId(this.tourId)
        console.log('значение даты id', this.tourId)
      } catch (error) {
        console.error('Error fetching tour dates:', error);
      }
    },
    // Получение данных о датах тура
    async fetchDataToursId(tourId) {
      try {
        if (!tourId) {
          return;
        }

        const response = await axios.get(`${BASE_URL}/tour-dates/${tourId}/tour-date`);

        // Предположим, что ответ API включает массив объектов tour_date
        this.tourDates = response.data || [];

        console.log('tour_dates:', this.tourDates);

        // Установите firstDateId в идентификатор первой даты, если она существует
        this.firstDateId = this.tourDates.length > 0 ? this.tourDates[0].date_id : null;

      } catch (error) {
        console.error('Error fetching tour dates:', error);
      }
    },
        // Загрузка данных при выборе опции
    async loadData() {
      if (this.selectedOption) {
        try {
          const response = await axios.get(`${BASE_URL}/moto/${this.selectedOption}`);
          this.selectedMoto = response.data;
          this.selectedMoto = this.motoList.find(moto => moto.id === this.selectedOption);
          // Set the selectedMotoPrice to the price of the selected moto
          this.selectedMotoPrice = this.selectedMoto ? this.selectedMoto.price : '';
        } catch (error) {
          console.error('Error fetching moto data:', error);
        }
      } else {
        this.selectedMoto = null;
      }
    },
    // Загрузка списка мотоциклов для выбранного тура 
    async loadMotoList(tourId) {
      try {
        const response = await axios.get(`${BASE_URL}/tours/${tourId}/motos`);
        console.log('Aывести туры с чекбоками:', response.data);

        this.motoList = response.data;
      } catch (error) {
        console.error('Error fetching moto list:', error);
      }
    },
  },




};

</script>

<style>
form {
  margin-bottom: 20px;
}

select {
  margin-left: 10px;
  padding: 5px;
}

button {
  margin-top: 10px;
}

input,
textarea {
  margin-right: 10px;
  padding: 5px;
}

h2 {
  margin-top: 20px;
}

#newFio {
  width: 260px
}

.my_div {
  padding: 10px
}


#newPrice,
#newDoplata,
#newAllSumma,
#newAvance,
#newdecimal,
#input2,
#result,
#newSeriaPassport,
#newNumberPassport,
#newKodPassport {
  width: 80px;
}

#newBirthdate, #newDataPassport, #newDataPrava{
  width: 100px;
}
</style>

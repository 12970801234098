<template>
  <div id="app">
    <!-- <BikeUsersVue/> -->
    <header v-if="$store.state.isAuthenticated">
      <!-- Отобразить активную ссылку вместо h1 -->
      <router-link to="/dashboard" :class="{ 'active': $route.path === '/tours' }">Туры</router-link>
      <router-link v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
 to="/participants"
        :class="{ 'active': $route.path === '/participants' }">Участники</router-link>
      <router-link v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
 to="/equipment"
        :class="{ 'active': $route.path === '/equipment' }">Арендная техника</router-link>
      <router-link v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
 to="/gid"
        :class="{ 'active': $route.path === '/gid' }">Гиды</router-link>
      <router-link to="/baza" :class="{ 'active': $route.path === '/baza' }">База</router-link>

      <!-- <button v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
 v-show="isVisibleButton" @mouseover="showMenu"
        @mouseout="hideMenu">
        Показать меню
      </button> -->

      <!-- Всплывающее меню -->
      <div >
        <button @click="goToProfile" v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
 >Профиль</button>
        <button @click="logout" style="margin-left: 10px;" v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
>Выход</button>
      </div>

      <button v-if="($store.state.userRole === 'user') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('user'))" @click="logout">Выход</button>
    </header>
    <router-view />
    <LoginForm v-if="!$store.state.isAuthenticated" />



  </div>
</template>

<script>
// import BikeUsersVue from './components/BikeUsers.vue';
// import BikeToor from './components/BikeToor.vue';
// import TableBiker from './components/TableBiker.vue';

export default {
  name: 'App',
  components: {
    // BikeUsersVue



    // TableBiker
  },
  data() {
    return {
      isMenuVisible: false,
      isVisibleButton: true,
      isAuthenticated: true, // Предположим, что изначально пользователь не аутентифицирован
    };
  },
  methods: {
    async logout() {
      localStorage.removeItem('token');

      // Очистка состояния в хранилище Vuex
      this.$store.commit('LOGOUT');

      // Дополнительные действия, если необходимо
      // Например, перенаправление на страницу входа
      this.$router.push('/login');
    },
    showMenu() {
      this.isMenuVisible = true;
      this.isVisibleButton = false; // Скрыть кнопку "Показать меню"
      console.log('$store.state.isAuthenticated:', this.$store.state.isAuthenticated);
      console.log('$store.state.userRole:', this.$store.state.userRole);
    },
    hideMenu() {
      this.isMenuVisible = false;
    },
    onMenuMouseOver() {
      this.isMenuVisible = true;
      this.isVisibleButton = false; // Скрыть кнопку "Показать меню"
    },
    onMenuMouseOut() {
      this.isMenuVisible = false;
      this.isVisibleButton = true; // Показать кнопку "Показать меню"
    },
    goToProfile() {
      // Логика перехода на страницу профиля
      this.$router.push('/profil');
      console.log('Переход на страницу профиля');
    },



  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  position: relative;
}

body {
  margin: 0;
}

header {
  margin-bottom: 70px;
  background-color: #3498db;
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
}

header button {
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

/* Стиль для активной ссылки */
header a.active {
  font-weight: bold;
  /* или другие стили для выделения */
}

div[aria-hidden="true"] {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

div[aria-hidden="false"] {
  display: block;
}</style>

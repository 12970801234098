<template>
    <div>
        <!-- <MyHeader/> -->
        <BikeToor />
    </div>
</template>

<script>
import BikeToor from '@/components/BikeToor.vue';
// import MyHeader from '@/components/MyHeader.vue';

export default {
    name: 'DashBoard',
    components: {
        BikeToor,
    },
};
</script>
<template>
  <div class="all">
    <!-- 
    Модальное окно с информацией о клиенте -->
    <div v-if="isModalVisible" class="modal-overlay">
      <div class="modal">
        <!-- <pre>{{ userDetails }}</pre> -->
        <!-- Крестик для закрытия модального окна -->
        <span class="modal-close" @click="hideModal">
          <i class="fas fa-times"></i>
        </span>
        <div>
          <p v-if="userDetails[0]?.user"><b> {{ userDetails[0]?.user?.fio }}</b></p>
          <!-- <p v-if="userDetails[0]?.user">Дата рождения: {{ userDetails[0]?.user?.DateBirth }}</p> -->
          <p v-if="userDetails[0]?.user"><b> {{ userDetails[0]?.user?.phone }}</b></p>
        </div>
        <!-- Ваш контент модального окна -->


        <div v-for="(item, index) in userDetails" :key="index">
          <h2> {{ item.tourDate?.tour?.tour_name }}</h2>
          <table>
            <thead>
              <tr>

                <th>Дата начала тура</th>
                <th>Дата конец тура </th>
                <th>Цена тура</th>
                <th>Аванс</th>
                <th>№ cчета</th>
                <th>Доплата</th>
                <th>Общая сумма за тур</th>

                <th>Оплачено </th>
                <th>% Скидка</th>
                <th>Арендная техника</th>
                <th>Кол-во дней аренды</th>
                <th>Цена аренды</th>
                <th>Договор на тур</th>
                <th>Договор на аренду</th>
                <th>Гостиница</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ formatDate(item.tourDate?.tour_date) }}</td>

                <td>{{ formatDate(item.tourDate?.tour_date_end) }}</td>


                <td>{{ item.rental_tour }}</td>
                <td>{{ item.avance_tour }}</td>
                <td>{{ item.schet }}</td>
                <td>{{ item.doplata_tour }}</td>
                <td>{{ item.total_tour }}</td>
                <td>{{ item.oplacheno }}</td>
                <td>{{ item.percent }}</td>
                <td>
                  <span v-if="item.moto">{{ item.moto.equipment_name }}</span>
                  <span v-else>Нет данных</span>
                </td>
                <!-- <td>{{ item.moto.equipment_name }}</td> -->


                <td>{{ item.rental_days }}</td>

                <td> {{ item.total_rental_cost }}</td>


                <td v-if="item.dogovor_po_turu"> <a target="_blank"
                    :href="`${BASE_URL}/uploads/${item.dogovor_po_turu}`"> {{
      truncateString(item.dogovor_po_turu) }} </a></td>
                <td v-else>Нет данных</td>

                <td v-if="item.dogovor_na_arendu"><a target="_blank"
                    :href="`${BASE_URL}:3000/uploads/${item.dogovor_na_arendu}`">{{
      truncateString(item.dogovor_na_arendu) }}</a></td>
                <td v-else>Нет данных</td>

                <td>
                  <span v-if="item.is_busy">Забронировано</span>
                  <span v-else>Не забронировано</span>
                </td>
              </tr>
              <!-- и так далее, извлекайте нужные данные как вам необходимо -->
            </tbody>
          </table>
        </div>
        <button @click="hideModal">Закрыть</button>
      </div>
    </div>


<!-- Список клиентов -->
    <div class="tour-menu bike">

        <!-- Таблица со списком клиентов -->

      <h2>Список клиентов</h2>
      <input type="text" v-model="searchQuery" placeholder="Поиск по клиентам" />
      <table>
        <thead>
                  <!-- Заголовки столбцов -->
          <tr>
            <th>№</th>
            <th>ФИО</th>
            <th>Телефон</th>
            <th>Дата рождения</th>
            <th>Серия паспорта</th>
            <th>Номер паспорта</th>
            <th>Кем выдан паспорт</th>
            <th>Дата выдачи паспорта</th>
            <th>Код подразделения</th>
            <th>Место рождения</th>
            <th>Дата регистрации</th>
            <th>Место регистрации</th>

            <th>Водительское удостоверение</th>
            <th>Дата выдачи водительского удостоверения</th>
            <th>Действия</th>
          </tr>
        </thead>
        <!-- Значения ячеек таблицы -->
        <tbody>
          <tr v-for="(user, index) in filteredUsers" :key="index" @click="showTourDates(index)">
            <td>

              {{ index + 1 }}
            </td>
            <td>
              <template v-if="!user.isEditing">{{ user.fio }}</template>

              <template v-else>
                <input type="text" v-model="user.fioEdit" />
              </template>
            </td>
            <td>

              <template v-if="!user.isEditing">{{ user.phone }}</template>

              <template v-else>
                <input type="text" v-model="user.phoneEdit" />
              </template>
            </td>
            <td>

              <template v-if="!user.isEditing">{{ formatDate(user.DateBirth) }}</template>

              <template v-else>
                <input type="text" v-maska data-maska="####.##.##" v-model="user.DateBirthEdit" id="originalDate"
                  @input="formatDateInput" />
              </template>
            </td>

            <td>

              <template v-if="!user.isEditing">{{ user.passportSeries }}</template>

              <template v-else>
                <input type="text" v-model="user.passportSeriesEdit" />
              </template>
            </td>
            <td>

              <template v-if="!user.isEditing">{{ user.passportNumber }}</template>

              <template v-else>
                <input type="text" v-model="user.passportNumberEdit" />
              </template>
            </td>
            <td>

              <template v-if="!user.isEditing">{{ user.passportKemVydan }}</template>

              <template v-else>
                <input type="text" v-model="user.passportKemVydanEdit" />
              </template>
            </td>
            <td>

              <template v-if="!user.isEditing">{{ formatDate(user.passportVydan) }}</template>

              <template v-else>
                <input type="text" v-maska data-maska="####.##.##" v-model="user.passportVydanEdit" />
              </template>
            </td>


            <td>

              <template v-if="!user.isEditing">{{ user.passportKodP }}</template>

              <template v-else>
                <input type="text" v-model="user.passportKodPEdit" />
              </template>
            </td>
            <!-- место регистрации -->
            <td>

              <template v-if="!user.isEditing">{{ user.birthplace }}</template>

              <template v-else>
                <input type="text" v-model="user.birthplaceEdit" />
              </template>
            </td>
            <!-- дата регистрации -->
            <td>

              <template v-if="!user.isEditing">{{ formatDate(user.birthdate) }}</template>

              <template v-else>
                <input type="text" v-maska data-maska="####.##.##" v-model="user.birthdateEdit" @input="reverseInput" />
              </template>
            </td>

            <td>

              <template v-if="!user.isEditing">{{ user.registrationAddress }}</template>

              <template v-else>
                <input type="text" v-model="user.registrationAddressEdit" />
              </template>
            </td>
            <!-- конец -->
            <td>

              <template v-if="!user.isEditing">{{ user.driverLicense }}</template>

              <template v-else>
                <input type="text" v-model="user.driverLicenseEdit" />
              </template>
            </td>
            <td>

              <template v-if="!user.isEditing">{{ formatDate(user.driverLicenseDate) }}</template>

              <template v-else>
                <input v-maska data-maska="####.##.##" v-model="user.driverLicenseDateEdit" />

              </template>
            </td>
            <!-- Add similar blocks for other fields -->
            <td>
                        <!-- Кнопки действий -->
              <span
                v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                <button v-if="!user.isEditing" @click="editTour(index)">
                  <i class="fas fa-edit"></i>
                </button>
                <button v-else @click="saveTour(index)">
                  <i class="fas fa-save"></i> Сохранить
                </button>
                <button @click="deleteMoto(index)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </span>

              <button @click="showUserDetails(user.id)">
                <i class="fas fa-eye"></i>
              </button>

            </td>
          </tr>
        </tbody>
      </table>


    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { vMaska } from "maska"
import '@fortawesome/fontawesome-free/css/all.css';
import { BASE_URL } from '../config'; // Импортируем базовый URL

export default {
  directives: { maska: vMaska },
  data() {
    return {

      selectedTour: null,// selectedTour - Индекс выбранного тура.
      editIndex: null,// editIndex - Индекс элемента, который редактируется.
      users: [],// users - Список пользователей.
      searchQuery: '', // searchQuery - Строка запроса для фильтрации пользователей.
      selectedUserDetails: null,// selectedUserDetails - Подробная информация о выбранном пользователе.
      userDetails: null,// userDetails - Подробная информация о турах выбранного пользователя.
      // modalShow: false,
      isDetailsVisible: false,// isDetailsVisible - Флаг видимости дополнительной информации о турах пользователя.
      selectedEquipmentName: null,// selectedEquipmentName - Название выбранного оборудования.
      isModalVisible: false,// isModalVisible - Флаг видимости модального окна.

    };
  },

  created() {
    this.fetchTours();
  },
  computed: {
    // вычисляет массив пользователей (users), который будет отфильтрован в соответствии с условиями, определенными внутри функции filter. Конкретно, каждый пользователь будет проверен на соответствие условиям, определенным внутри filter, которые основаны на строке поискового запроса searchQuery.
    filteredUsers() {
      const query = this.searchQuery.toLowerCase();
      return this.users.filter(user =>
        (user.fio && user.fio.toLowerCase().includes(query)) ||
        (user.phone && user.phone.includes(query))
        // Add null checks for other fields as needed
      );
    },

    computed: {
      truncatedDogovorPoTuru() {
        if (this.items.length > 0) {
          // Вернуть первые 10 символов строки
          return this.items[0].dogovor_po_turu.substring(0, 10);
        }
        return '';
      },
    },
    formattedDate() {
      return this.formatDateForInput(this.user.DateBirthEdit);
    },
  },
  methods: {
    reverseDate: function (date) {
      // Разбиваем строку на массив по точкам
      var dateArray = date.split('.');

      // Инвертируем порядок элементов массива
      var reversedDateArray = dateArray.reverse();

      // Собираем инвертированную строку обратно
      this.reversedDate = reversedDateArray.join('.');
    },

    reverseInput() {
      if (this.user && this.user.driverLicenseDateEdit) {
        this.user.driverLicenseDateEdit = this.user.driverLicenseDateEdit
          .split("")
          .reverse()
          .join("");
      }
    },
    dateToString(date) {
      if (!(date instanceof Date)) {
        console.error('Invalid date object.');
        return '';
      }

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.error('Invalid time value');
        return '';
      }

      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('ru-RU', options);
    },
    // //  formatDate - Метод для форматирования даты в формат "День Месяц Год".
    formatDateInput() {
      // Ваш код для форматирования user.DateBirthEdit
      // Например, если вы хотите изменить формат на DD.MM.YYYY, можете использовать что-то вроде:
      // this.user.DateBirthEdit = formatDateToDDMMYYYY(this.user.DateBirthEdit);
    },
// Метод для отображения модального окна с подробной информацией о турах пользователя.
    showModal() {
      this.isModalVisible = true;
    },
    // скрыть модалку
    hideModal() {
      this.isModalVisible = false;
    },
    // //  toggleDetailsVisibility - Метод для переключения видимости дополнительной информации о турах пользователя.
    toggleDetailsVisibility() {
      this.isDetailsVisible = !this.isDetailsVisible;
    },
    // показать 
    showTourDates(index) {
      if (this.selectedTour === index) {
        this.selectedTour = null;
      } else {
        this.selectedTour = index;
        this.editIndex = null; // Сбрасываем индекс редактирования
      }
    },
// Метод для загрузки подробной информации о выбранном пользователе.
    async fetchUserDetails(userId) {
      try {
        const response = await axios.get(`${BASE_URL}/arenda-status/users/${userId}`);
        console.log('User Details Response:', response);
        this.selectedUserDetails = response.data;
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    },
    // Метод для отображения подробной информации о выбранном пользователе и его турах.
    async showUserDetails(userId) {
      try {
        const response = await axios.get(`${BASE_URL}/arenda-status/users/${userId}`);
        console.log('User Details Response:', response);
        this.userDetails = response.data || [];

        // Вывод данных в консоль для отслеживания
        console.log('User Details:', this.userDetails);
        this.selectedEquipmentName = this.userDetails[0]?.user?.moto?.equipment_name;
        // Откройте модальное окно
        this.showModal();
      } catch (error) {
        console.error('Error fetching user details:', error);
        console.log('Error Details:', error.response);
      }
    },


    // Метод для загрузки списка пользователей.
    async fetchTours() {
      try {
        const response = await axios.get(`${BASE_URL}/users`);
        console.log('API Response:', response);
        console.log('API Data:', response.data);

        this.users = response.data.map(user => {
          return {
            id: user.id,
            fio: user.fio.trim(),
            phone: user.phone,
            DateBirth: user.DateBirth,
            passportSeries: user.passportSeries,
            passportNumber: user.passportNumber,
            passportKemVydan: user.passportKemVydan,
            passportVydan: user.passportVydan,
            passportKodP: user.passportKodP,
            birthdate: user.birthdate,
            birthplace: user.birthplace,

            registrationAddress: user.registrationAddress,

            driverLicense: user.driverLicense,
            driverLicenseDate: user.driverLicenseDate,
          };
        });
        // Log names of all motos
        this.users.forEach(user => {
          console.log(user.id);
        });
      } catch (error) {
        console.error('Error fetching motos:', error);
      }
    },
    // Метод для удаления выбранного пользователя.
    async deleteMoto(index) {
      try {
        const confirmation = confirm('Вы уверены, что хотите удалить этого клиента?');
        if (confirmation) {
          const userId = this.users[index].id;

          try {
            await axios.delete(`${BASE_URL}/users/${userId}`);
            console.log(`${BASE_URL}/users/${userId}`);
            this.users.splice(index, 1); // Remove the deleted moto directly
            this.selectedTour = null;
          } catch (error) {
            console.error('Error deleting moto:', error);
          }
        }
      } catch (error) {
        console.error('Error handling delete confirmation:', error);
      }
    },
    // редактирование клиента
    editTour(index) {
      // Set editing state to true for the clicked user
      this.users[index].isEditing = true;
      this.users[index].fioEdit = this.users[index].fio;
      this.users[index].phoneEdit = this.users[index].phone;
      this.users[index].DateBirthEdit = this.users[index].DateBirth;
      this.users[index].passportSeriesEdit = this.users[index].passportSeries;
      this.users[index].passportNumberEdit = this.users[index].passportNumber;
      this.users[index].passportKemVydanEdit = this.users[index].passportKemVydan;
      this.users[index].passportVydanEdit = this.users[index].passportVydan;
      this.users[index].passportKodPEdit = this.users[index].passportKodP;
      this.users[index].driverLicenseEdit = this.users[index].driverLicense;
      this.users[index].driverLicenseDateEdit = this.users[index].driverLicenseDate;
      this.users[index].registrationAddressEdit = this.users[index].registrationAddress
      this.users[index].birthdateEdit = this.users[index].birthdate,
        this.users[index].birthplaceEdit = this.users[index].birthplace


    },
    // обновить данные клиента
    updateTour(index) {
      const userId = this.users[index].id;
      const updatedUser = {
        fio: this.users[index].fio,
        phone: this.users[index].phone,
        DateBirth: this.users[index].DateBirth,
        passportSeries: this.users[index].passportSeries,
        passportNumber: this.users[index].passportNumber,
        passportKemVydan: this.users[index].passportKemVydan,
        passportVydan: this.users[index].passportVydan,
        passportKodP: this.users[index].passportKodP,
        driverLicense: this.users[index].driverLicense,
        driverLicenseDate: this.users[index].driverLicenseDate,
        registrationAddress: this.users[index].registrationAddress,
        birthdate: this.users[index].birthdate,
        birthplace: this.users[index].birthplace
        // ... (добавьте другие поля, если необходимо)
      };

      axios.put(`${BASE_URL}/users/${userId}`, updatedUser)
        .then(response => {
          console.log('User updated successfully:', response.data);
        })
        .catch(error => {
          console.error('Error updating user:', error);
        });
    },
    // сохранить данные клиента
    saveTour(index) {
      this.updateTour(index);

      // Save the changes and set editing state back to false
      this.users[index].isEditing = false;
      this.users[index].fio = this.users[index].fioEdit;
      this.users[index].phone = this.users[index].phoneEdit;
      this.users[index].DateBirth = this.users[index].DateBirthEdit;
      this.users[index].passportSeries = this.users[index].passportSeriesEdit;
      this.users[index].passportNumber = this.users[index].passportNumberEdit;
      this.users[index].passportKemVydan = this.users[index].passportKemVydanEdit;
      this.users[index].passportVydan = this.users[index].passportVydanEdit;
      this.users[index].passportKodP = this.users[index].passportKodPEdit;
      this.users[index].driverLicense = this.users[index].driverLicenseEdit;
      this.users[index].driverLicenseDate = this.users[index].driverLicenseDateEdit;
      this.users[index].registrationAddress = this.users[index].registrationAddressEdit,
        this.users[index].birthdate = this.users[index].birthdateEdit,
        this.users[index].birthplace = this.users[index].birthplaceEdit
      // ... (добавьте другие поля, если необходимо)

      // Clear the editing properties after saving
      this.users[index].fioEdit = '';
      this.users[index].phoneEdit = '';
      this.users[index].DateBirthEdit = '';
      // ... (очистите другие свойства редактирования, если необходимо)
    },
    //  formatDate - Метод для форматирования даты в формат "День Месяц Год".
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('ru-RU', options);
    },
  //  Метод для обрезания строки до первых 10 символов.
    truncateString(str) {
      // Вернуть первые 10 символов строки
      return str.substring(0, 10);
    },



  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Полупрозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80vw;
  height: 80vh;
  /* Set a maximum height for the modal content */
  overflow: auto;
  /* Enable vertical scrolling */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

/* Общие стили */
.all {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  flex-direction: column;
}

/* Стили для таблицы с деталями пользователя и датами тура */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  width: auto;
}

th {
  background-color: #f2f2f2;
}

/* Стили для кнопок редактирования и удаления в таблице */
.edit-button,
.delete-button {
  padding: 10px;
  margin: 5px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #2980b9;
}

.edit-button i,
.delete-button i {
  margin-right: 5px;
}

/* Стили для списка клиентов и поискового поля */
.tour-menu.bike {
  padding-left: 20px;
}

input {
  min-width: 150px;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

/* Стили для таблицы клиентов */
.tour-menu table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tour-menu th,
.tour-menu td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.tour-menu th {
  background-color: #f2f2f2;
}

/* Стили для кнопок в таблице клиентов */
.tour-menu button {
  padding: 8px;
  margin: 5px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tour-menu button:hover {
  background-color: #2980b9;
}

.tour-menu button i {
  margin-right: 5px;
}
</style>

// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    isAuthenticated: false,
    userRole: null,
    token: null, // Добавлено для хранения токена
    decodedToken: null, // Добавлено для хранения декодированных данных
  },

  mutations: {
    SET_USER(state, user) {
      state.isAuthenticated = true; // Устанавливаем флаг аутентификации в true
      state.user = user; // Сохраняем данные пользователя
     
    },
    setUserRole(state, role) {
          state.userRole = role;
        },
    LOGOUT(state) {
      state.isAuthenticated = false; // Сбрасываем флаг аутентификации при выходе
      state.user = null; // Очищаем данные пользователя
    },
  },
  actions: {
    async logout({ commit }) {
      // Здесь может быть логика для отправки запроса на сервер для выхода
      // Например, с использованием axios или другой библиотеки для HTTP-запросов
      // Затем обновите состояние через мутацию
      commit('setAuthentication', false); 
    },
  }
});

<template>
  <div class="login-form">
    <h2>Login</h2>
    <form v-if="!showRecoveryForm" @submit.prevent="login" class="form">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" required class="form-control">
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required class="form-control">
      </div>
      <button type="submit" class="btn">Login</button>
    </form>

    <div class="forgot-password" v-if="!showRecoveryForm">
      <p>Забыли ваш пароль?</p>
      <button @click="showRecoveryForm = true" class="btn-link">Восстановить</button>
    </div>

    <!-- Форма восстановления -->
    <form v-if="showRecoveryForm" @submit.prevent="recover" class="form">
      <h2>Восстановить Login/Password</h2>
      <div class="form-group">
        <label for="recovery-email">Email:</label>
        <input type="email" id="recovery-email" v-model="recoveryEmail" required class="form-control">
      </div>
      <button type="submit" class="btn">Восстановить </button>
      <button @click="showRecoveryForm = false" type="button" class="btn-link">Отменить</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '../config'; 
export default {
  data() {
    return {
      username: '',
      password: '',
      showRecoveryForm: false,
      recoveryEmail: '',//•	recoveryEmail: Строка, содержащая электронную почту для восстановления пароля
      protectedResourceData: null,//•	protectedResourceData: Объект, содержащий данные защищенного ресурса.
      protectedResourceData1: null,//•	protectedResourceData1: Объект, содержащий данные второго защищенного ресурса.
      roles: [],//роли пользователей
      user: '',
      // roles:''
    };
  },
// mounted(): Хук жизненного цикла, вызываемый после того, как компонент был вставлен в DOM.
  async mounted() {
    const storedRoles = localStorage.getItem('setUserRole');
    console.log('Stored roles:', storedRoles);

    if (storedRoles) {
      try {
        const rolesArray = JSON.parse(storedRoles).roles;
        console.log('Restored roles:', rolesArray);
        this.roles = rolesArray;
        this.getProtectedResource1()
        // ... далее ваш код ...
      } catch (error) {
        console.error('Error parsing stored roles:', error);
      }
    }
  
  },
  methods: {
    async checkTokenAndFetchResource(token) {
      try {
        if (token) {
          await this.getProtectedResource1(token);
         
        }
      } catch (error) {
        console.error('Error fetching protected resource:', error);
      }
    },
// вход
    async login() {
      try {
        const response = await axios.post(`${BASE_URL}/auth/login`, {
          login: this.username,
          password: this.password,
        }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const token = response.data.access_token;

        // Сохраняем токен в localStorage
        localStorage.setItem('token', token);

        const user = response.data.user.login; // Данные пользователя с сервера
        const roles = response.data.user.role
        console.log('тут roles', roles)
        console.log('тут user', user)
        // Manually update the roles in the component's state
        this.roles = roles;
        this.user = user;
        this.$store.commit('SET_USER', user); // Вызываем мутацию для сохранения данных пользователя в состояние Vuex
        this.$store.commit('setUserRole', roles);
        localStorage.setItem('setUserRole', JSON.stringify(roles));

        console.log('JSON.stringify(roles)', JSON.stringify(roles))
        // Далее ваш код для перехода на защищенный ресурс
        this.getProtectedResource();
      } catch (error) {
        console.error('Login error:', error);
      }
    },

    // Метод для получения защищенного ресурса BeerTOken
    async getProtectedResource() {
      try {
        const token = localStorage.getItem('token');
        // Посылаем GET-запрос на защищенный ресурс с токеном
        const response = await axios.get(`${BASE_URL}/auth/protected-resource`, {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        });

        const user = response.data.user;
        console.log('Loaded user data:', user);

        // Update the user data in the component's state
        this.user = user;

        // Optionally, update the Vuex store or other components
        this.$store.commit('SET_USER', user);
        console.log('Protected Resource:', response.data);
        this.protectedResourceData = response.data;
        console.log('protectedResourceData', this.protectedResourceData)
        this.$router.push('/dashboard');
      } catch (error) {
        console.error('Error getting protected resource:', error);
      }
    },

// Метод для получения второго защищенного ресурса.
    async getProtectedResource1() {
  try {
    const token = localStorage.getItem('token');
    const roles = localStorage.getItem('setUserRole');

    console.log('getProtectedResource1(roles)', roles);

    // Подождем, пока роли будут извлечены
    await new Promise((resolve) => setTimeout(resolve, 100));

    const response1 = await axios.get(`${BASE_URL}/auth/protected-resource`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const user1 = response1.data.user;
    console.log('Loaded user data1:', user1);

    // Update the user data in the component's state
    this.user = user1;

    // Optionally, update the Vuex store or other components
    this.$store.commit('SET_USER', user1);
    this.protectedResourceData1 = response1.data;
    console.log('Protected Resource1:', this.protectedResourceData1);
    return this.$router.push('/dashboard');
  } catch (error) {
    console.error('Error getting protected resource:', error);
  }
},

  }


};
</script>

<style scoped>
.login-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
}

.btn {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-link {
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  text-decoration: underline;
}

.btn:hover {
  background-color: #2980b9;
}
</style>
